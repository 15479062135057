import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FaChevronDown } from 'react-icons/fa';

const faqs = [
  {
    question: 'Как найти нужное лекарство через Базили?',
    answer: `Поиск лекарств в Базили максимально прост:

1. Введите название препарата в поиск

2. Сравните варианты:
   • Цены в разных аптеках
   • Наличие поблизости
   • Аналоги с низкими ценами

3. Выберите и закажите`
  },
  {
    question: 'Как работает поиск лекарств?',
    answer: `Умный поиск Базили поможет найти то, что нужно:

1. Поиск понимает:
   • Неточные названия
   • Действующие вещества
   • Симптомы

2. Результаты включают:
   • Все формы выпуска
   • Дозировки
   • Производителей

3. Сортировка по вашим критериям:
   • По цене
   • По расстоянию
   • По наличию`
  },
  {
    question: 'Как происходит подбор аналогов?',
    answer: `Базили автоматически находит аналоги по трем критериям:

1. Состав:
   • Действующее вещество
   • Дозировка
   • Форма выпуска

2. Стоимость:
   • От недорогих до премиум
   • С учетом скидок
   • Акции в аптеках`
  },
  {
    question: 'Как записаться на анализы?',
    answer: `Запись на анализы - это просто:

1. Выберите анализы:
   • По названию
   • Из готовых комплексов
   • По рекомендации врача

2. Запишитесь онлайн:
   • Удобная дата
   • Ближайшая лаборатория
   • Моментальное подтверждение`
  },
  {
    question: 'Как обеспечивается безопасность данных?',
    answer: `Ваши данные под надежной защитой:

1. Безопасность:
   • Шифрование данных
   • Защищенные серверы
   • Двухфакторная аутентификация

2. Контроль:
   • Доступ только для вас
   • Прозрачная история
   • Возможность удаления данных`
  },
  {
    question: 'Как работает доставка лекарств?',
    answer: `Выберите удобный способ получения:

1. Самовывоз:
   • Бронь в ближайшей аптеке
   • Хранение 48 часов
   • Без комиссии

2. Доставка:
   • Выбор времени
   • Отслеживание заказа
   • Оплата при получении`
  },
  {
    question: 'Насколько достоверна информация о лекарствах?',
    answer: `Мы отвечаем за точность данных:

1. Источники:
   • Государственный реестр
   • Инструкции производителей
   • База Минздрава

2. Обновление:
   • Ежедневная проверка
   • Контроль фармацевтов
   • Актуальные цены`
  },
  {
    question: 'Как получить консультацию по препаратам?',
    answer: `Помощь доступна 24/7:

1. Онлайн:
   • Чат с фармацевтом
   • Умный бот
   • База знаний

2. По телефону:
   • Горячая линия
   • Обратный звонок
   • Экстренная консультация`
  },
  {
    question: 'Что делать при неотложной ситуации?',
    answer: `В экстренных случаях следуйте этому алгоритму:

1. При угрозе жизни и здоровью:
   • Немедленно звоните 112 или 103
   • Не пытайтесь заниматься самолечением
   • Следуйте инструкциям диспетчера скорой помощи

2. В неэкстренных, но срочных случаях:
   • Используйте функцию "Срочная консультация" в приложении
   • Получите быструю связь с дежурным врачом
   • Следуйте полученным рекомендациям

Базили не заменяет экстренную медицинскую помощь и предназначен для плановых обращений.`
  },
  {
    question: 'Как защищены мои персональные данные?',
    answer: `Базили обеспечивает максимальную защиту ваших данных:

1. Технические меры защиты:
   • Шифрование всех персональных данных
   • Защищенные серверы с регулярным резервным копированием
   • Двухфакторная аутентификация для доступа к личному кабинету

2. Организационные меры:
   • Соответствие требованиям 152-ФЗ "О персональных данных"
   • Строгий контроль доступа к информации
   • Регулярный аудит систем безопасности

3. Ваши права:
   • Возможность удаления всех персональных данных
   • Контроль доступа к медицинской информации
   • Прозрачная политика конфиденциальности`
  }
];

const FAQ: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  return (
    <section id="faq" className="py-20 bg-white scroll-mt-20">
      <div className="container">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
            Часто задаваемые вопросы
          </h2>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Ответы на популярные вопросы о сервисе Базили
          </p>
        </motion.div>

        <div className="max-w-3xl mx-auto">
          {faqs.map((faq, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              viewport={{ once: true }}
              className="mb-4"
            >
              <button
                onClick={() => setActiveIndex(activeIndex === index ? null : index)}
                className="w-full flex items-center justify-between p-4 bg-gray-50 hover:bg-gray-100 rounded-lg transition-colors"
              >
                <span className="font-medium text-left">{faq.question}</span>
                <FaChevronDown
                  className={`transform transition-transform ${
                    activeIndex === index ? 'rotate-180' : ''
                  }`}
                />
              </button>
              <AnimatePresence>
                {activeIndex === index && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: 'auto', opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    className="overflow-hidden"
                  >
                    <div className="p-4 text-gray-600 whitespace-pre-line">
                      {faq.answer}
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
