import React from 'react';
import { motion } from 'framer-motion';
import { FaRobot, FaClinicMedical, FaUserMd, FaMobileAlt } from 'react-icons/fa';

const features = [
  {
    icon: <FaRobot className="text-4xl text-primary" />,
    title: 'ИИ-рекомендации',
    description: 'Умный подбор лекарств и анализов на основе ваших симптомов'
  },
  {
    icon: <FaClinicMedical className="text-4xl text-secondary" />,
    title: 'Поиск по аптекам',
    description: 'Сравнение цен и наличия в ближайших аптеках'
  },
  {
    icon: <FaUserMd className="text-4xl text-accent" />,
    title: 'Консультации',
    description: 'Быстрая связь с врачами через телемедицину'
  },
  {
    icon: <FaMobileAlt className="text-4xl text-primary" />,
    title: 'Личный кабинет',
    description: 'История заказов и напоминания о приёме лекарств'
  }
];

const Features: React.FC = () => {
  return (
    <section id="features" className="py-20 bg-gray-50">
      <div className="container">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
            Возможности Базили
          </h2>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Используйте все преимущества современных технологий для заботы о вашем здоровье
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              viewport={{ once: true }}
              className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow"
            >
              <div className="mb-4">{feature.icon}</div>
              <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
