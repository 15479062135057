import React from 'react';
import { motion } from 'framer-motion';
import { FaSearch, FaCalendarAlt, FaPills } from 'react-icons/fa';

const Hero: React.FC = () => {
  return (
    <section className="bg-gradient-to-r from-primary/5 to-secondary/5 py-20">
      <div className="container">
        <div className="grid md:grid-cols-2 gap-12 items-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <h1 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
              Ваш умный медицинский помощник
            </h1>
            <p className="text-lg text-gray-600 mb-8">
              Базили помогает найти лекарства, записаться на анализы и получить медицинские консультации - всё в одном месте
            </p>
            <div className="flex flex-wrap gap-4">
              <a href="https://t.me/bazilee" target="_blank" rel="noopener noreferrer" className="btn btn-primary">
                Наш Канал
              </a>
            </div>
          </motion.div>
          
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="grid grid-cols-2 gap-6"
          >
            <div className="bg-white p-6 rounded-xl shadow-lg">
              <FaSearch className="text-primary text-2xl mb-4" />
              <h3 className="font-semibold mb-2">Поиск лекарств</h3>
              <p className="text-gray-600 text-sm">Найдите нужные препараты и сравните цены</p>
            </div>
            <div className="bg-white p-6 rounded-xl shadow-lg">
              <FaCalendarAlt className="text-secondary text-2xl mb-4" />
              <h3 className="font-semibold mb-2">Запись на анализы</h3>
              <p className="text-gray-600 text-sm">Удобная запись в любую лабораторию</p>
            </div>
            <div className="bg-white p-6 rounded-xl shadow-lg col-span-2">
              <FaPills className="text-accent text-2xl mb-4" />
              <h3 className="font-semibold mb-2">Покупка лекарств</h3>
              <p className="text-gray-600 text-sm">Заказ с доставкой или бронирование в аптеке</p>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
