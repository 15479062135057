import React from 'react';
import { FaTwitter, FaFacebook, FaTelegram, FaInstagram } from 'react-icons/fa';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-gray-900 text-gray-300">
      <div className="container py-12">
        <div className="grid md:grid-cols-3 gap-8">
          {/* Логотип и описание */}
          <div className="col-span-1">
            <img src="/logo_main_dark.svg" alt="Базили" className="h-8 mb-4" />
            <p className="text-sm">
              Базили - ваш умный медицинский помощник. Мы делаем заботу о здоровье простой и доступной.
            </p>
          </div>

          {/* Навигация */}
          <div className="text-center">
            <h3 className="text-white font-semibold mb-4">Сервис</h3>
            <ul className="space-y-2">
              <li><a href="#features" className="hover:text-white transition-colors">Возможности</a></li>
              <li><a href="#benefits" className="hover:text-white transition-colors">Преимущества</a></li>
              <li><a href="#faq" className="hover:text-white transition-colors">FAQ</a></li>
              
            </ul>
          </div>

          {/* Контакты */}
          <div className="text-right">
            <h3 className="text-white font-semibold mb-4">Контакты</h3>
            <ul className="space-y-2">
              <li>Email: bazileeinfo@gmail.com</li>
            </ul>
            <div className="flex justify-end space-x-4 mt-4">
              <a 
                href="https://t.me/bazilee" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-2xl hover:text-primary transition-colors"
              >
                <FaTelegram />
              </a>
            </div>
          </div>
        </div>

        <div className="border-t border-gray-800 mt-8 pt-8 text-sm text-center">
          <p>&copy; {currentYear} Базили - Здоровье - это просто. Все права защищены.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
