import React from 'react';
import { motion } from 'framer-motion';

const Header: React.FC = () => {
  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header className="bg-white shadow-sm fixed w-full top-0 z-50">
      <div className="container py-4 flex items-center justify-between">
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.5 }}
        >
          <img src="/logo_main.svg" alt="Базили" className="h-10" />
        </motion.div>
        <motion.nav
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="hidden md:flex items-center space-x-8"
        >
          <button onClick={() => scrollToSection('features')} className="text-gray-600 hover:text-primary">
            Возможности
          </button>
          <button onClick={() => scrollToSection('benefits')} className="text-gray-600 hover:text-primary">
            Преимущества
          </button>
          <button onClick={() => scrollToSection('faq')} className="text-gray-600 hover:text-primary">
            FAQ
          </button>
          <a 
            href="https://t.me/bazilee" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="btn btn-primary"
          >
            Наш Канал
          </a>
        </motion.nav>
      </div>
    </header>
  );
};

export default Header;
