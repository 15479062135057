import React from 'react';
import { motion } from 'framer-motion';
import { FaClock, FaShieldAlt, FaRobot, FaHeartbeat } from 'react-icons/fa';

const benefits = [
  {
    icon: <FaClock className="text-5xl text-primary" />,
    title: 'Экономия времени',
    description: 'Быстрый поиск лекарств и запись на анализы без очередей и звонков'
  },
  {
    icon: <FaShieldAlt className="text-5xl text-secondary" />,
    title: 'Надежность',
    description: 'Только проверенные аптеки и медицинские лаборатории'
  },
  {
    icon: <FaRobot className="text-5xl text-accent" />,
    title: 'Умные рекомендации',
    description: 'ИИ анализирует симптомы и предлагает оптимальные решения'
  },
  {
    icon: <FaHeartbeat className="text-5xl text-primary" />,
    title: 'Забота о здоровье',
    description: 'Регулярные напоминания и мониторинг вашего здоровья'
  }
];

const Benefits: React.FC = () => {
  return (
    <section id="benefits" className="py-20 bg-gradient-to-b from-white to-gray-50">
      <div className="container">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
            Почему выбирают Базили
          </h2>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Мы делаем заботу о здоровье простой и удобной
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {benefits.map((benefit, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              viewport={{ once: true }}
              className="text-center"
            >
              <div className="mb-6 flex justify-center">
                {benefit.icon}
              </div>
              <h3 className="text-xl font-semibold mb-3">
                {benefit.title}
              </h3>
              <p className="text-gray-600">
                {benefit.description}
              </p>
            </motion.div>
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
          viewport={{ once: true }}
          className="mt-16 text-center"
        >
          <a href="https://t.me/bazilee" target="_blank" rel="noopener noreferrer" className="btn btn-primary">
            Наш Канал
          </a>
        </motion.div>
      </div>
    </section>
  );
};

export default Benefits;
